// Import the functions you need from the SDKs you need
import { getApps, getApp, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, signInWithRedirect, OAuthProvider, signInWithCredential, signInAnonymously, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCnIqCYv9OuBj0XiM7LZC32oEH9F5zzyC8",
  authDomain: "running-f0e25.firebaseapp.com",
  projectId: "running-f0e25",
  storageBucket: "running-f0e25.appspot.com",
  messagingSenderId: "507100247720",
  appId: "1:507100247720:web:53347e0041016ee6fb349d",
  measurementId: "G-X0CCFQFH8G"
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();



export const getCurrentUser = () => auth.currentUser; // 현재 로그인된 사용자 정보

export const listenAuthState = (callback) => {
  const unsubscribe = onAuthStateChanged(auth, callback);
  return unsubscribe; // 구독 해제 함수 반환
};

export const signInAnonymouslyUser = async () => {
  try {
    const result = await signInAnonymously(auth);
    // console.log(result);
    return result.user; // 익명 사용자 정보 반환
  } catch (error) {
    console.error("익명 로그인 오류:", error);
    throw error;
  }
};

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    return result.user; // Google 사용자 정보 반환
  } catch (error) {
    console.error("Google 로그인 오류:", error);
    throw error;
  }
};


export const signInWithEmail = async (email, password) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    return result; // 로그인 성공 시 사용자 정보 반환
  } catch (error) {
    console.error('Firebase 이메일 로그인 에러:', error);
    throw error; // 에러를 호출한 곳으로 전달
  }
};

export const signUpWithEmail = async (email, password) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error(error);
  }
};


// Google 계정 로그아웃 함수
export const signOutUser = async () => {
  try {
    await signOut(auth);
    console.log('User signed out');
  } catch (error) {
    console.error("Error signing out: ", error);
  }
};
