import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Quill 에디터의 스타일을 포함
import Quill from 'quill';
import ImageResize from 'quill-image-resize';

// Quill 에디터에 이미지 리사이징 모듈을 등록합니다.
// 모듈을 한 번만 등록
if (!Quill.imports['modules/imageResize']) {
    Quill.register('modules/imageResize', ImageResize);
}
const Editor = () => {
    // 폰트 옵션을 등록하기 위한 Quill 포맷 추가
    const Font = Quill.import('formats/font');
    Font.whitelist = ['my-font', 'sans-serif', 'serif']; // 'my-font'는 CSS에 정의된 클래스 이름
    Quill.register(Font, true);

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            [{ 'font': Font.whitelist }],
            [{ 'size': ['small', false, 'large', 'huge'] }],  // 새로운 사이즈 옵션
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],  // 들여쓰기 옵션
            [{ 'align': [] }],  // 텍스트 정렬 옵션
            [{ 'color': [] }, { 'background': [] }],  // 색상 및 배경색상 변경 옵션
            ['link', 'image', 'video'],
            // ['clean']  // 포맷 제거
        ],
        clipboard: {
            matchVisual: false
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    };

    return (
        <ReactQuill
            theme="snow"
            modules={modules}
        />
    );
}

export default Editor;
