import React from 'react';
import { TableRow, TableCell } from '@mui/material';


const MainPostsNoResult = ({ post, index, handleClick }) => {

    return <TableRow >
        <TableCell
            sx={{ width: '386px', height: '27px' }}
            colSpan={5} align="center">
            검색 결과가 없습니다.
        </TableCell>
    </TableRow>;
};

export default MainPostsNoResult;