import axios from 'axios';

// 공통으로 사용할 axios 인스턴스
const CustomAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // 기본 서버 주소 
    // withCredentials: true // cookie를 서버로 전송하도록
    headers: {
        'Content-Type': 'application/json',
    },
});

// // axios interceptor
// customAxios.interceptors.response.use(
//     // response 시 컴포넌트에 결과를 전달해주기 전 실행되는 코드
//     // history에 등록하는 역할을 함
//     (res) => {

//         // request url을 api 주소와 param으로 분리
//         const splitedUrl = res.config.url.split("?");
//         const apiUrl = splitedUrl[0];
//         const param = splitedUrl.length > 1 ? splitedUrl[1] : ""

//         // 즐겨찾기 등록, 해제 api는 history에 담지 않음
//         if (apiUrl.includes("Favorite")) {
//             return res;
//         }

//         axios.post(`${process.env.REACT_APP_API_URL}/sa/saveHistory.do`, {
//             path: window.location.pathname,
//             apiUrl,
//             param
//         }).catch((err) => { })

//         return res
//     },
//     // response 시 에러 발생했을 때,
//     // 컴포넌트에 결과를 전달하기 전 실행됨
//     // 401 일 경우 login 페이지로 이동하도록 함.
//     (error) => {
//         if (error.status && error.status === 401) {
//             window.location.href = '/login';
//         // } else if (error.status && error.status === 405) {
//         //     window.location.href = '/';
//         }
//         return Promise.reject(error)
//     })

export default CustomAxios;