import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Auth from "./components/sns/Auth";
import Post from "./components/posts/Post";
import Callback from "./components/sns/Callback";
import UserComponent from './components/user_info/UserComponent';
import Editor from "./components/editor/Editor";
import Editor2 from "./components/editor/Editor2";
import LoadingProgress from "./components/common/LoadingProgress";
import MainPage from "./page/MainPage";
import PostsPage from "./page/PostsPage";
import PostViewPage from "./page/PostViewPage";
import PostCreatePage from "./page/PostCreatePage";
import PostModifyPage from "./page/PostModifyPage";
import Login from "./components/login/Login";


export var ddf = 1;
const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/posts" element={<PostsPage />} />
          <Route path="/posts/:docId" element={<PostViewPage />} />
          <Route path="/postCreate" element={<PostCreatePage />} />
          <Route path="/postsModify/:docId" element={<PostModifyPage />} />

          {/* <Route path="/callback/naver_login" element={<Callback />} />
          <Route path="/editor" element={<Editor />} />
          <Route path="/editor2" element={<Editor2 />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/2" element={<UserComponent nick_name={1} />} /> */}

        </Routes>
      </Router>
      <LoadingProgress />
    </>

  );
};

export default App;