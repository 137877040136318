import React from 'react';
import { Container, Box, Button, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { dummyCategoryList, userAtom } from '../../store';
import Login from './Login';
import { useNavigate } from 'react-router-dom';

const Menus = () => {
    const navigate = useNavigate(); // useNavigate 훅 사용하여 navigate 함수 가져오기


    const handleCategoryClick = (key) => {
        // 쿼리 파라미터로 categoryKey 전달
        navigate(`/posts?category=${key}`);
    };

    return (
        <Container maxWidth="md" sx={{ padding: 0 }}>
            <Box display='flex' justifyContent='space-between' alignItems='center'
            // sx={{
            //     // backgroundColor: '#2222DA',
            //     paddingX: 1,
            //     paddingY: 1,
            // }}
            >
                {/* 카테고리 리스트 */}
                <Box display="flex" gap={2}>
                    {dummyCategoryList.map((category) => (
                        <Typography
                            key={category.key}
                            variant="subtitle1"
                            sx={{ cursor: 'pointer', color: '#555' }} // 원하는 스타일로 변경
                            onClick={() => handleCategoryClick(category.key)}
                        >
                            {category.value}
                        </Typography>
                    ))}
                </Box>
            </Box>
        </Container>
    );
};


export default Menus;
