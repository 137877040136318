import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

// 로딩 ui 표시 여부
export const isLoadingAtom = atom(false);

// 사용자 정보
export const userAtom = atomWithStorage('user', null);

// 글 읽었는지에 대한 정보
export const readPostAtom = atomWithStorage('readPost', []);

// // 사이드바 접힘 여부
// export const isSidebarCollapsed = atom(true);
// // 사이드바 고정 여부
// export const isSidebarFixed = atomWithStorage('fixsidebar', false);

// // 권한 목록
// export const menuPermissionAtom = atom([]);


// // 탭 관련
// const tabStorage = createJSONStorage(
//     () => sessionStorage,
// )
// export const tabHistoryAtom = atomWithStorage('TABS', [
//     {
//         id: 0,
//         path: "/",
//         title: "Main 화면"
//     }
// ], tabStorage);

// // history, favorite
// export const historyAtom = atom([])
// export const favoriteAtom = atom([])


export const dummyCategoryList = [
    {
        key: 0,
        value: '전체'
    },
    {
        key: 1,
        value: "자유게시판"
    },
    {
        key: 2,
        value: "부상"
    },
    {
        key: 3,
        value: "3"
    },
    {
        key: 4,
        value: "4"
    },
    {
        key: 5,
        value: "5"
    },
    {
        key: 6,
        value: "6"
    },
];
