import CustomAxios from '../common/CustomAxios'; // CustomAxios 경로
import { signInWithGoogle, signOutUser } from '../sns/google/firebase'; // 구글 로그인, 로그아웃 함수 경로


export const checkLogin = async (user, setUser, setShowModal) => {
    
    if (!!user?.nick_name === false) {
      if (window.confirm("로그인 후 이용 가능합니다. 로그인 하시겠습니까?")) {
        try {
          const currentUser = await signInWithGoogle();

          const searchParams = new URLSearchParams();
          searchParams.append("email", currentUser.email);

          const response = await CustomAxios(`/getUser?${searchParams.toString()}`);

          if (response.data.error === "User is blocked") {
            alert("차단된 사용자입니다. 로그인이 불가능합니다.");
            await signOutUser(); // 로그아웃 처리
            return false;
          }

          if (response.data.message === "User not found, signup required") {
            setUser((prevUser) => ({
              ...prevUser, // 기존 상태를 보존
              email: currentUser.email
            }));
            setTimeout(() => setShowModal(true), 0); // 회원가입 모달 표시. 상태가 업데이트된 후 모달을 띄움
          } else {
            setUser(response.data.result); // 유저 정보 저장
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        return false;
      }
    }

    return true;
  };