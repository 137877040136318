import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Avatar, Button, Pagination, IconButton, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { recentPosts } from '../json/recentlyData';
import { useAtom } from "jotai";
import { dummyCategoryList, isLoadingAtom, userAtom } from "../../store";
import CustomAxios from '../common/CustomAxios';
import TimestampFormatter from '../common/TimestampFormatter';
import { ArrowForward, ChevronRightOutlined, ImageOutlined, LineWeight, MoreHorizOutlined, MoreOutlined, MoreSharp, MoveDown, MoveToInbox, MoveToInboxOutlined, MovieFilterOutlined } from '@mui/icons-material';
import NumberWithComma from '../common/NumberWithComma';


const MainPosts = ({ post, index, handleClick }) => {

    return <TableRow
        key={post.id}
        sx={{
            '&:last-child td, &:last-child th': { border: 0 },
            '&:hover': {
                backgroundColor: 'rgba(25, 101, 192, 0.3)', // 원하는 색상으로 변경
            },
            backgroundColor: index % 2 === 0 ? 'rgba(25, 101, 192, 0.1)' : 'transparent'
        }}
    >
        <TableCell
            sx={{ maxWidth: '80px', overflow: 'hidden', color: '#e8a72d' }} align="center"
        >
            {dummyCategoryList.map((item) => {
                if (item.key == post.category) {
                    return <Typography key={item.key} variant="body2" component="span">{item.value}</Typography>;
                }
            })}
        </TableCell>
        <TableCell
            onClick={() => handleClick(post)}
            sx={{ width: '168px', maxWidth: '168px', cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
            <Box
                display='flex'
                alignItems='center'
            >
                {post.have_image && <ImageOutlined color="primary" />}
                <Typography variant="subtitle1" component="span">{post.title}</Typography>
                {post.comment_count
                    ? <Typography
                        variant="caption"
                        sx={{
                            color: "#404040",
                            backgroundColor: "#a0f0f0",
                            display: 'inline-block',
                            margin: '3px 0 0 6px',
                            padding: 0,
                            verticalAlign: 'top'
                        }}
                    >
                        {post.comment_count}
                    </Typography>
                    : null}
            </Box>
        </TableCell>
        <TableCell sx={{ maxWidth: '60px', paddingRight:"4px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} padding="none" align="center">
            <Typography variant='subtitle2'>
                {post.created_by}
            </Typography>
        </TableCell>
        <TableCell sx={{ maxWidth: '80px' }} padding="none" align="center"><TimestampFormatter timestampSeconds={post.created_at._seconds} /></TableCell>
    </TableRow>;
};

export default MainPosts;