import React, { useRef, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import ImageResize from 'quill-image-resize';

// 모듈을 한 번만 등록
if (!Quill.imports['modules/imageResize']) {
    Quill.register('modules/imageResize', ImageResize);
}
const Font = Quill.import('formats/font');
Font.whitelist = ['my-font', 'sans-serif', 'serif'];
Quill.register(Font, true);

const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        [{ 'font': Font.whitelist }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'align': [] }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'image', 'video'],
    ],
    clipboard: {
        matchVisual: false
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const Editor2 = () => {
    const quillRef = useRef(null);
    const [content, setContent] = useState('');

    useEffect(() => {
        if (quillRef.current) {
            const quillInstance = quillRef.current.getEditor();

            // 에디터 내용 변경 이벤트 리스너 추가
            quillInstance.on('text-change', (delta, oldDelta, source) => {
                setContent(quillInstance.root.innerHTML);
            });
        }
    }, []);


    const insertText = () => {
        if (quillRef.current) {
            const quillInstance = quillRef.current.getEditor();
            quillInstance.insertText(quillInstance.getSelection(true).index, ' 삽입된 텍스트 ');
        }
    };

    const clearContent = () => {
        if (quillRef.current) {
            const quillInstance = quillRef.current.getEditor();
            quillInstance.setText('');
            setContent(''); // useState의 content도 업데이트
        }
    }

    return (
        <div>
            <ReactQuill
                theme="snow"
                modules={modules}
                ref={quillRef}
                value={content}
                onChange={setContent}
            />
            <button onClick={insertText}>텍스트 삽입</button>
            <button onClick={clearContent}>내용 지우기</button>
        </div>
    );
};

export default Editor2;