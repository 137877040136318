import React from 'react';
import { format as formatDate, isToday } from 'date-fns';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const TimestampFormatter = ({ timestampSeconds }) => {
  if (timestampSeconds == null) {
    return <Typography variant='subtitle2'>방금</Typography>;
  }

  try {
    const timestamp = new Date(timestampSeconds * 1000);
    const isTodayDate = isToday(timestamp);

    if (isTodayDate) {
      return <Typography variant='subtitle2'>{formatDate(timestamp, 'HH:mm:ss')}</Typography>;
    } else {
      return <Typography variant='subtitle2'>{formatDate(timestamp, 'yy/MM/dd')}</Typography>;
    }
  } catch (error) {
    console.error('Invalid timestampSeconds:', error);
    return <Typography variant='subtitle2'>날짜 정보 없음</Typography>;
  }
};

TimestampFormatter.propTypes = {
  timestampSeconds: PropTypes.number,
  format: PropTypes.string,
};

export default TimestampFormatter;