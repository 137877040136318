import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Container } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import useLogin from './useLogin';

const Login = () => {
    const { user, handleEmailLogin, handleGoogleSnsLogin, signOut, error } = useLogin();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        if (!email || !password) {
            alert('이메일과 비밀번호를 입력해주세요.');
            return;
        }

        try {
            await handleEmailLogin(email, password);
        } catch (err) {
            console.error('로그인 실패:', err);
        }
    };

    return (
        <Container
            maxWidth="xs"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh', // 화면 전체 높이
            }}
        >
            {/* 로그인 박스 */}
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                {/* 사이트명 */}
                <Typography variant="h5" align="center" gutterBottom>
                    Running Mania
                </Typography>

                {/* 오류 메시지 */}
                {error && (
                    <Typography variant="body2" color="error" align="center">
                        {error}
                    </Typography>
                )}

                {/* EMAIL 입력 */}
                <TextField
                    label="EMAIL"
                    variant="outlined"
                    fullWidth
                    required
                    size="small"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                {/* PW 입력 */}
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    required
                    size="small"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={user?.nick_name ? signOut : handleLogin}
                >
                    {user?.nick_name ? '로그아웃' : '로그인'}
                </Button>

                {/* Google SNS 로그인 버튼 */}
                <Button
                    variant="outlined"
                    startIcon={<GoogleIcon />}
                    fullWidth
                    onClick={handleGoogleSnsLogin}
                >
                    Google 계정으로 로그인
                </Button>

                {/* 현재 사용자 닉네임 표시 */}
                {user?.nick_name && (
                    <Typography variant="body1" align="center" gutterBottom>
                        안녕하세요, {user.nick_name}님!
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default Login;
