import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Modal,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  Typography,
} from '@mui/material';
import TimestampFormatter from "../common/TimestampFormatter";
import NumberWithComma from "../common/NumberWithComma";
import { useAtom } from "jotai";
import { isLoadingAtom, userAtom } from "../../store";
import CustomAxios from "../common/CustomAxios";
import { EditNoteOutlined, EditOutlined, ImageOutlined, SearchOutlined } from "@mui/icons-material";
import { signInWithGoogle } from "../sns/google/firebase";
import { signOut } from "firebase/auth";
import Join from "../login/Join";
import { checkLogin } from "../login/checkLogin";

const POSTS_PER_PAGE = 20;

// 쿼리 파라미터:
// page: 페이지 번호 (숫자). 기본값은 1.
// size: 한 페이지에 보여줄 게시글 수 (숫자). 기본값은 10.
// category: 게시글 카테고리 필터 (문자열). 특정 카테고리의 게시글만 가져올 수 있습니다.
// search: 검색어 (문자열). 제목이나 내용에 검색어가 포함된 게시글만 가져옵니다

const Posts = () => {
  const location = useLocation();
  const navigate = useNavigate(); // useNavigate 훅 사용하여 navigate 함수 가져오기
  const [user, setUser] = useAtom(userAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchType, setSearchType] = useState('title');
  const [searchTerm, setSearchTerm] = useState('');
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState();
  const [totalCount, setTotalCount] = useState(1);
  const [showModal, setShowModal] = useState(false);

  // URLSearchParams를 사용하여 쿼리 파라미터 추출
  const queryParams = new URLSearchParams(location.search);
  const categoryKey = queryParams.get('category');


  const startIndex = (currentPage - 1) * POSTS_PER_PAGE;
  const endIndex = startIndex + POSTS_PER_PAGE;
  // const displayedPosts = filteredPosts.slice(startIndex, endIndex);
  const totalPages = Math.ceil(totalCount / POSTS_PER_PAGE);

  useEffect(() => {
    if (categoryKey) {
      // 여기에 API 호출 또는 상태 업데이트 로직 추가
      if (categoryKey == '0') {
        setCategory(null);
      } else {
        setCategory(categoryKey);
      }
    } else {
      // categoryKey가 없을 경우 전체 데이터 표시 등 처리
      // console.log('No Category Key provided. Displaying all posts.');
    }
  }, [categoryKey]);

  useEffect(() => {
    onClickSearch();
  }, [category]);

  function handlePageChange(event, value) {
    setPage(value);
    onClickSearch(value, null);
  }

  // Function to get posts
  function onClickSearch(page = 1) {
    const searchParams = new URLSearchParams();
    searchParams.append("page", page);
    searchParams.append("category", category);

    setIsLoading(true);

    CustomAxios(`/getPosts?${searchParams.toString()}`)
      .then((result) => {
        const res = result.data;
        setPosts(res.result);
        setTotalCount(res.totalCount);
        setIsLoading(false);
      }).catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }

  function handleRowClick(postId) {
    navigate(`/posts/${postId}`);
  }

  const onClickWrite = useCallback(async () => {
    if ( !!user?.nick_name == false ) {
      if (await checkLogin(user, setUser, setShowModal) === false) {
        return;
      }

    } else {
      navigate(`/postCreate`);
    }
  }, [user]);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Box sx={{ my: 2, display: 'flex', justifyContent: 'end' }}>
        <Button variant="outlined" startIcon={<EditNoteOutlined />} onClick={onClickWrite}><Typography variant='button'>글쓰기</Typography></Button>
      </Box>

      <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="search-type-label">검색 유형</InputLabel>
          <Select
            labelId="search-type-label"
            id="search-type"
            size="small"
            value={searchType}
            label="검색 유형"
            onChange={(e) => setSearchType(e.target.value)}
          >
            <MenuItem value="title" >제목</MenuItem>
            <MenuItem value="author">작성자</MenuItem>
            {/* 실제 데이터 구조에 따라 코멘트 검색 메뉴를 추가해야 합니다.
            <MenuItem value="comment">댓글</MenuItem>
            */}
          </Select>
        </FormControl>
        <TextField
          label="검색어"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button variant="outlined" startIcon={<SearchOutlined />} onClick={onClickSearch}><Typography variant='button'>조회</Typography></Button>
      </Box>

      <Paper sx={{ mb: 2 }}>
        <TableContainer component={Paper} >
          <Table size="small">
            <TableHead >
              <TableRow >
                <TableCell align="center">제목</TableCell>
                <TableCell sx={{ width: '80px' }} padding="none" align="center">글쓴이</TableCell>
                <TableCell sx={{ width: '80px' }} padding="none" align="center"> 등록일</TableCell>
                <TableCell sx={{ width: '10%' }} padding="none" align="center">조회</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {posts.map((post, index) => (
                <TableRow
                  key={post.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': {
                      backgroundColor: 'rgba(25, 101, 192, 0.3)', // 원하는 색상으로 변경
                    },
                    backgroundColor: index % 2 === 0 ? 'rgba(25, 101, 192, 0.1)' : 'transparent'
                  }}
                >
                  <TableCell
                    onClick={() => handleRowClick(post.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                    >
                      {post.have_image && <ImageOutlined color="primary" />}
                      {post.title}{post.comment_count ?
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#404040",
                            backgroundColor: "#a0f0f0",
                            fontWeight: 600,
                            display: 'inline-block',
                            margin: '3px 0 0 6px',
                            padding: 0,
                            verticalAlign: 'top'
                          }}
                        >
                          {post.comment_count}
                        </Typography>

                        : null}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: '80px' }} padding="none" align="center">{post.created_by}</TableCell>
                  <TableCell sx={{ width: '80px' }} padding="none" align="center"><TimestampFormatter timestampSeconds={post.created_at._seconds} /></TableCell>
                  <TableCell sx={{ width: '100px' }} padding="none" align="center"><NumberWithComma number={post.read_count} /></TableCell>
                </TableRow>
              ))}
              {totalCount === 0 && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    검색 결과가 없습니다.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}

          siblingCount={1}
          boundaryCount={1}
          // variant="outlined"
          shape="rounded"


        />
      </Box>


      <Box sx={{ my: 2, display: 'flex', justifyContent: 'end' }}>
        <Button variant="outlined" startIcon={<EditNoteOutlined />} onClick={onClickWrite}><Typography variant='button'>글쓰기</Typography></Button>
      </Box>
      <Modal open={showModal} onClose={closeModal}>
          <Box>
              <Join setShowModal={setShowModal} />
          </Box>
      </Modal>
    </>
  );
};

export default Posts;
