import React, { useState } from "react";

const NaverLogin = () => {
  const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const STATE = "false"; // or "Y"
  const REDIRECT_URI = "http://localhost:3000/callback/naver_login";

  const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECT_URI}`;

  const naverLogin = () => {
    const width = 500;
    const height = 600;
    const left = window.screenX + (window.innerWidth - width) / 2;
    const top = window.screenY + (window.innerHeight - height) / 2;

    window.open(
      NAVER_AUTH_URL,
      "Naver Login",
      `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`
    );
  };

  // 팝업 창에서 호출할 함수
  window.naverAuthCallback = (token) => {
    console.log("인증 완료:", token);
    // 이후 필요한 로직 처리 (예: 서버로 인증 코드 전송)
  };

  return <button onClick={naverLogin}>네이버 로그인</button>;
};

export default NaverLogin;
