import React from 'react';

const NumberWithComma = ({ number }) => {
  // 숫자를 천 단위로 콤마를 넣어주는 함수
  const formatNumberWithCommas = (num) => {
    if (typeof num !== 'number' && typeof num !== 'string') return '';
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return <span>{formatNumberWithCommas(number)}</span>;
};

export default NumberWithComma;