import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import { Box, Container } from '@mui/material';

export default function PositionedMenu({
  comment,
  onClickCommentDelete,
  onClickCommentBlock,
  onClickCommentReport,
}) {
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClickContextMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseContextMenu = () => {
      setAnchorEl(null);
    };

  return (
    <Box>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickContextMenu}
        size="small"
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseContextMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={2} // 그림자 강도 조정
        PaperProps={{
          style: {
            minWidth: 100, // 최소 너비 설정
            backgroundColor: '#fff', // 배경색 설정
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // 그림자 설정
          },
        }}
      >
        <MenuItem onClick={(e) => { handleCloseContextMenu(); onClickCommentDelete(e, comment.id); }}>
          삭제
        </MenuItem>
        <MenuItem onClick={(e) => { handleCloseContextMenu(); onClickCommentBlock(e, comment.id); }}>
          차단
        </MenuItem>
        <MenuItem onClick={(e) => { handleCloseContextMenu(); onClickCommentReport(e, comment.id); }}>
          신고
        </MenuItem>
      </Menu>
    </Box>
  );
}