import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Checkbox, FormControlLabel, Typography, CircularProgress, Container } from '@mui/material';
import { useAtom } from 'jotai';
import { userAtom } from '../../store';
import CustomAxios from '../common/CustomAxios';
import { listenAuthState, signInAnonymouslyUser, signInWithGoogle, signOutUser } from '../sns/google/firebase';
import { forwardRef } from 'react';

const Join = forwardRef(({ setShowModal }, ref) => {
    const [user, setUser] = useAtom(userAtom);
    const [consent, setConsent] = useState(false); // 개인정보 이용 동의 상태
    const [isApiWaiting, setIsApiWaiting] = useState(false);

    const handleSignup = async () => {
        if (!consent) {
            alert('You must agree to the privacy policy to sign up.');
            return;
        }

        setIsApiWaiting(true);

        try {
            const response = await CustomAxios('/createUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    uid: user.uid,
                    email: user.email,
                    consent: true,
                }),
            });

            if (response.data && response.data.result) {
                setUser(response.data.result); // 회원가입 성공 시 사용자 정보 업데이트
                setShowModal(false); // 모달 닫기
            } else {
                console.error('회원가입 응답에 유저 정보가 없습니다:', response.data);
                alert('회원가입 중 문제가 발생했습니다. 다시 시도해주세요.');
            }
        } catch (error) {
            console.error('회원가입 실패:', error);
            alert('회원가입 중 문제가 발생했습니다. 관리자에게 문의해주세요.');
        } finally {
            setIsApiWaiting(false);
        }
    };


    return (
        <Container>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    height: 300,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    <Typography variant="h6" gutterBottom>
                        회원가입
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={consent}
                                onChange={(e) => setConsent(e.target.checked)}
                            />
                        }
                        label="개인정보 이용 동의 (필수)"
                    />
                </Box>
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={handleSignup}
                        disabled={!consent}
                        fullWidth
                    >
                        {isApiWaiting ?
                            <div style={{ width: '20px', height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress size={20} />
                            </div>
                            : '회원가입'}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
});

export default Join;
