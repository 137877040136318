import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Modal,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import TimestampFormatter from "../common/TimestampFormatter";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import ImageResize from 'quill-image-resize';
import CustomAxios from "../common/CustomAxios";
import { useAtom } from "jotai";
import { isLoadingAtom, userAtom, readPostAtom, dummyCategoryList } from "../../store";
import { BlockOutlined, DeleteOutline, EditOutlined, ModeEditOutline, ReportOutlined, StopOutlined, ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpOutlined } from "@mui/icons-material";
import PositionedMenu from "./PositionedMenu";
import { signInWithGoogle } from "../sns/google/firebase";
import { signOut } from "firebase/auth";
import Join from "../login/Join";
import useCheckLogin, { checkLogin } from "../login/checkLogin";

// 모듈을 한 번만 등록
if (!Quill.imports['modules/imageResize']) {
  Quill.register('modules/imageResize', ImageResize);
}
// 폰트 옵션 추가
const Font = Quill.import('formats/font');
Font.whitelist = ['my-font', 'sans-serif', 'serif']; // 필요한 폰트 추가
Quill.register(Font, true);
// import {ThumbUpAltIcon, ThumbDownAltIcon} from '@mui/icons-material';

const modules = {
  toolbar: false,
  clipboard: {
    matchVisual: true
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }
};

const Post = () => {
  const location = useLocation();
  const { docId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [readPost, setReadPost] = useAtom(readPostAtom);
  const [post, setPost] = useState({});
  const [postReaction, setPostReaction] = useState('');
  const [commentsReaction, setCommentsReaction] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [commentProgress, setCommentProgress] = useState(false);
  const [category, setCategory] = useState();
  const [showModal, setShowModal] = useState(false);

  // URLSearchParams를 사용하여 쿼리 파라미터 추출
  const queryParams = new URLSearchParams(location.search);
  const categoryKey = queryParams.get('category');

  useEffect(() => {
    if (categoryKey) {
      // 여기에 API 호출 또는 상태 업데이트 로직 추가
      if (categoryKey == '0') {
        setCategory(null);
      } else {
        setCategory(categoryKey);
      }
    } else {
      // categoryKey가 없을 경우 전체 데이터 표시 등 처리
      // console.log('No Category Key provided. Displaying all posts.');
    }
  }, [categoryKey]);

  useEffect(() => {
    if (user != null) {
      onClickSearch();
      onClickSearchReaction();
      onClickCommentsSearch();
      onClickSearchCommentsReaction();

      if (readPost.includes(docId) == false) { //한번만 호출
        // console.log(readPost);
        handleReadPost();
        setReadPost([...readPost, docId]);
      }
    }
  }, [user]);


  const handleAddComment = (newCommentJson) => {
    // console.log(comments);
    // console.log(newCommentJson);
    setComments([...comments, newCommentJson]);
    setNewComment('');
  };


  function goPageModify() {
    navigate(`/postsModify/${docId}`);
  };


  function onClickSearch() {
    const searchParams = new URLSearchParams();
    searchParams.append("docId", docId);

    setIsLoading(true);

    CustomAxios(`/getPost?${searchParams.toString()}`)
      .then((result) => {
        // console.log(result);
        if (result.status == 200) {
          const res = result.data;
          setPost(res.result);
        }
      }).catch((err) => {
        console.error(err);

      }).finally(() => {
        setIsLoading(false);
      });
  }

  function onClickSearchReaction() {
    const searchParams = new URLSearchParams();
    searchParams.append("docId", docId);
    searchParams.append("uid", user.uid);

    setIsLoading(true);

    CustomAxios(`/getPostReaction?${searchParams.toString()}`)
      .then((result) => {
        // console.log(result);
        if (result.status == 200) {
          const res = result.data;
          setPostReaction(res.result.type);
        }
      }).catch((err) => {
        console.error(err);

      }).finally(() => {
        setIsLoading(false);
      });
  }

  const handleReadPost = () => {
    CustomAxios(`/addReadCount`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed, e.g., authorization
      },
      data: JSON.stringify({
        docId: docId,
        uid: user.uid
      }),
    })
      .then((result) => {
        // console.log(result);
      }).catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function onClickCommentsSearch(page = 1) {
    const searchParams = new URLSearchParams();
    searchParams.append("page", page);
    searchParams.append("docId", docId);

    setIsLoading(true);

    CustomAxios(`/getComments?${searchParams.toString()}`)
      .then((result) => {
        // console.log(result);
        if (result.status == 200) {
          const res = result.data;
          setComments(res.result);
        }


      }).catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onClickSearchCommentsReaction() {
    const searchParams = new URLSearchParams();
    searchParams.append("docId", docId);
    searchParams.append("uid", user.uid);

    setIsLoading(true);

    CustomAxios(`/getCommentsReaction?${searchParams.toString()}`)
      .then((result) => {
        // console.log(result);
        if (result.status == 200) {
          const res = result.data;
          setCommentsReaction(res.result);
        }
      }).catch((err) => {
        console.error(err);

      }).finally(() => {
        setIsLoading(false);
      });
  }

  const onClickDelete = async (event) => {
    if (window.confirm("삭제하시겠습니까?") == false) {
      return;
    }
    
    setIsLoading(true);

    

    CustomAxios(`/deletePost`, { // Replace with your API endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed, e.g., authorization
      },
      data: JSON.stringify({
        docId: docId,
        nick_name: user.nick_name
      }),
    })
      .then((result) => {
        // console.log(result);

        alert("삭제 되었습니다.");
        onClickPosts();

      }).catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });

  };

  
  const onClickGood = useCallback(async () => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    setIsLoading(true);

    let action = "good";
    let type = "신규"; // 신규, 변경, 삭제

    switch (postReaction) {
      case 'good':
        action = '';
        type = "삭제";
        break;
      case 'bad':
        action = 'good';
        type = '변경';
        break;
      case '':
        action = 'good';
        type = '신규';
        break;
      default:
        break;
    }

    try {
      const response = await CustomAxios(`/toggleGood`, { // Replace with your API endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed, e.g., authorization
        },
        data: JSON.stringify({
          uid: user.uid,
          docId: docId,
          action: action,
        }),
      });

      setPostReaction(action);

      setPost((prevPost) => {
        const updatedPost = { ...prevPost };

        if (type === "신규") {
          updatedPost.good_count += 1;
        } else if (type === "변경") {
          updatedPost.good_count += 1;
          updatedPost.bad_count -= 1;
        } else if (type === "삭제") {
          updatedPost.good_count -= 1;
        }

        return updatedPost;
      });

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [user, docId, post]);

  const onClickBad = useCallback(async () => {
    
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    setIsLoading(true);

    let action = "bad";
    let type = "신규"; // 신규, 변경, 삭제

    switch (postReaction) {
      case 'bad':
        action = '';
        type = "삭제";
        break;
      case 'good':
        action = 'bad';
        type = "변경";
        break;
      case '':
        action = 'bad';
        type = "신규";
        break;
      default:
        break;
    }

    try {
      const response = await CustomAxios(`/toggleBad`, { // Replace with your API endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed, e.g., authorization
        },
        data: JSON.stringify({
          uid: user.uid,
          docId: docId,
          action: action,
        }),
      });

      setPostReaction(action);

      setPost((prevPost) => {
        const updatedPost = { ...prevPost };

        if (type === "신규") {
          updatedPost.bad_count += 1;
        } else if (type === "변경") {
          updatedPost.bad_count += 1;
          updatedPost.good_count -= 1;
        } else if (type === "삭제") {
          updatedPost.bad_count -= 1;
        }

        return updatedPost;
      });

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  function onClickPosts() {
    navigate(`/posts?category=${category}`);
  }

  const onClickCommentSave = useCallback(async () => {
    
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    if ( !!newComment == false || newComment.trim() == '' ) {
      alert("댓글 작성 후 저장 해 주세요.");
      return;
    }

    setCommentProgress(true);

    try {
      const response = await CustomAxios(`/addComment`, { // Replace with your API endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          uid: user.uid,
          nick_name: user.nick_name,
          docId: docId,
          content: newComment,
        }),
      });

      // console.log(result);
      if (response.status === 200) {
        const { result } = response.data;
        handleAddComment(result);
      } else {
        console.error(`Unexpected response status: ${response.status}`);
      }

    } catch (error) {
      console.error('Error saving comment:', error);
    } finally {
      setCommentProgress(false);
    }
  }, [user, docId, newComment]);

  const onClickCommentDelete = useCallback(async (event, commentId) => {
    
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    setIsLoading(true);

    if (window.confirm("삭제하시겠습니까?") == false) {
      return;
    }

    CustomAxios(`/deleteComment`, { // Replace with your API endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed, e.g., authorization
      },
      data: JSON.stringify({
        docId: docId,
        commentId: commentId,
        nick_name: user.nick_name
      }),
    })
      .then((result) => {
        // console.log(result);
        alert("삭제 되었습니다.");
        //TODO 화면값 갱신

      }).catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user]);

  const onClickCommentBlock = useCallback(async () => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

  
  }, [user]);

  const onClickCommentReport = useCallback(async () => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

  
  }, [user]);

  // 리액션 업데이트 함수 수정
  const updateCommentReaction = (commentId, action) => {
    setCommentsReaction(prevReactions => {
      // 이전 리액션에서 현재 댓글의 리액션을 찾음
      const existingReaction = prevReactions.find(
        reaction => reaction.commentId === commentId
      );

      // let action = "good";
      let type = "신규";

      // existingReaction이 있으면 해당 type 사용, 없으면 빈 문자열
      const prevType = existingReaction ? existingReaction.type : '';

      switch (prevType) {
        case action:
          action = '';
          type = "삭제";
          break;
        case 'good':
          action = 'bad';
          type = '변경';
          break;
        case 'bad':
          action = 'good';
          type = '변경';
          break;
        case '':
          action = action;
          type = '신규';
          break;
        default:
          break;
      }

      // 삭제인 경우
      if (type === "삭제") {
        return prevReactions.filter(reaction => reaction.commentId !== commentId);
      }

      // 변경이나 신규인 경우
      if (existingReaction) {
        return prevReactions.map(reaction =>
          reaction.commentId === commentId
            ? { ...reaction, type: action }
            : reaction
        );
      } else {
        return [...prevReactions, { commentId, type: action }];
      }
    });
  };

  const onClickCommentGood = useCallback(async (event, commentId) => {
    
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    setIsLoading(true);

    let action = "good";
    let type = "신규"; // 신규, 변경, 삭제

    // Assuming you have a way to get the current reaction for the comment
    const currentCommentReaction = reactionMap[commentId] || '';

    switch (currentCommentReaction) {
      case 'good':
        action = '';
        type = "삭제";
        break;
      case 'bad':
        action = 'good';
        type = '변경';
        break;
      case '':
        action = 'good';
        type = '신규';
        break;
      default:
        break;
    }

    try {
      const response = await CustomAxios(`/toggleCommentGood`, { // Replace with your API endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed, e.g., authorization
        },
        data: JSON.stringify({
          uid: user.uid,
          docId: docId,
          commentId: commentId,
          action: action,
        }),
      });

      updateCommentReaction(commentId, 'good');


      // 댓글 카운트 업데이트
      setComments(prevComments => {
        return prevComments.map(comment => {
          if (comment.id === commentId) {
            const updatedComment = { ...comment };
            if (type === "신규") {
              updatedComment.good_count += 1;
            } else if (type === "변경") {
              updatedComment.good_count += 1;
              updatedComment.bad_count -= 1;
            } else if (type === "삭제") {
              updatedComment.good_count -= 1;
            }
            return updatedComment;
          }
          return comment;
        });
      });

    } catch (error) {
      console.error('Error updating comment reaction:', error);
    } finally {
      setIsLoading(false);
    }
  
  }, [user]);

  const onClickCommentBad = useCallback(async (event, commentId) => {
    
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    setIsLoading(true);

    let action = "bad";
    let type = "신규"; // 신규, 변경, 삭제

    // Assuming you have a way to get the current reaction for the comment
    const currentCommentsReaction = reactionMap[commentId] || '';

    switch (currentCommentsReaction) {
      case 'bad':
        action = '';
        type = "삭제";
        break;
      case 'good':
        action = 'bad';
        type = '변경';
        break;
      case '':
        action = 'bad';
        type = '신규';
        break;
      default:
        break;
    }

    try {
      const response = await CustomAxios(`/toggleCommentBad`, { // Replace with your API endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed, e.g., authorization
        },
        data: JSON.stringify({
          uid: user.uid,
          docId: docId,
          commentId: commentId,
          action: action,
        }),
      });

      updateCommentReaction(commentId, 'bad');


      // 댓글 카운트 업데이트
      setComments(prevComments => {
        return prevComments.map(comment => {
          if (comment.id === commentId) {
            const updatedComment = { ...comment };
            if (type === "신규") {
              updatedComment.bad_count += 1;
            } else if (type === "변경") {
              updatedComment.bad_count += 1;
              updatedComment.good_count -= 1;
            } else if (type === "삭제") {
              updatedComment.bad_count -= 1;
            }
            return updatedComment;
          }
          return comment;
        })
      });

    } catch (error) {
      console.error('Error updating comment reaction:', error);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  // 배열을 객체로 변환 (null 체크 추가)
  const reactionMap = Array.isArray(commentsReaction)
    ? Object.fromEntries(
      commentsReaction.map(reaction => [reaction.commentId, reaction.type])
    )
    : {};

  const handleLoginRedirect = () => {
    // 로그인 페이지로 이동 예시
    window.location.href = "/login";
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Box my={2}>
        {/* 첫 줄: 카테고리, 타이틀 */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2" component="div" mr={1}>
              카테고리
            </Typography>
            <Typography variant="subtitle2" component="div">
              {
                dummyCategoryList.map(item => {
                  if (item.key == post.category) {
                    return item.value;
                  }
                })
              }
            </Typography>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div">
            {post.title}
          </Typography>
        </Box>

        {/* 두 번째 줄: 작성자, 작성시간 */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} mb={1}>

          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="text.secondary" mr={1}>
              작성자
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {post.created_by}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="text.secondary" mr={1}>
              작성시간
            </Typography>
            {
              post?.created_at?._seconds
              && (
                <TimestampFormatter timestampSeconds={post.created_at._seconds} />
              )}
          </Box>
        </Box>

        {/* 구분선 */}
        {/* <Divider sx={{ my: 2 }} /> */}

        {/* 세 번째 줄: 내용 */}
        <ReactQuill
          theme="snow"
          modules={modules}
          value={post.content}
          readOnly={true}
          className="custom-quill-editor"
        />

        {/* 구분선 */}
        {/* <Divider sx={{ my: 2 }} /> */}

        {/* 네 번째 줄: Good, Bad 버튼 */}
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Button variant="outlined" startIcon={postReaction == "good" ? <ThumbUp /> : <ThumbUpOutlined />}
            color="success"
            sx={{
              mr: 2,
              ...(postReaction == "good" && { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }) // 그림자 효과 추가
            }}
            onClick={onClickGood}
          >
            <Typography variant='button'>Good {post.good_count}</Typography>
          </Button>
          <Button variant="outlined" startIcon={postReaction == "bad" ? <ThumbDown /> : <ThumbDownOutlined />}
            color="error"
            sx={{
              ...(postReaction == "bad" && { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }) // 그림자 효과 추가
            }}
            onClick={onClickBad}
          >

            <Typography variant='button'>Bad {post.bad_count}</Typography>
          </Button>
        </Box>

        {/* 구분선 */}
        <Divider sx={{ my: 2 }} />

        <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between' }}>

          <Button variant="outlined" onClick={onClickPosts}><Typography variant='button'>목록</Typography></Button>

          <Box sx={{ my: 0, display: 'flex', justifyContent: 'space-between' }}>
            {
              user?.uid == post?.uid ?
                <>
                  <Button variant="outlined" startIcon={<DeleteOutline />} onClick={onClickDelete} sx={{ mr: '10px;' }}><Typography variant='button'>삭제</Typography></Button>
                  <Button variant="outlined" startIcon={<EditOutlined />} onClick={goPageModify}><Typography variant='button'>수정모드</Typography></Button>
                </>
                : null
            }
          </Box>
        </Box>


        {/* 다섯 번째 줄: 댓글 구역 */}
        <Typography variant="subtitl1" component="div" mb={2}>
          댓글
        </Typography>

        {/* 댓글 목록 */}
        <List>
          {
            comments ? comments.map((comment, index) => (
              <React.Fragment key={comment.id}>
                <Box my={2}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <Typography variant="subtitle2" component="div" mr={1}>
                        {comment.created_by}
                      </Typography>
                      {
                        comment?.created_at?._seconds
                          ? (
                            <TimestampFormatter timestampSeconds={comment.created_at._seconds} />
                          )
                          : <span>방금</span>
                      }
                    </Box>
                    <PositionedMenu
                      comment={comment}
                      onClickCommentDelete={onClickCommentDelete}
                      onClickCommentBlock={onClickCommentBlock}
                      onClickCommentReport={onClickCommentReport}
                    />
                  </Box>
                  <Typography variant="body1" component="div">
                    {comment.content}
                  </Typography>

                  <Box display="flex" justifyContent="end" alignItems="center">
                    {/* <Button>답글</Button> */}
                    <Box>
                      <Button
                        startIcon={reactionMap[comment.id] === 'good' ? <ThumbUp /> : <ThumbUpOutlined />}
                        color='success'
                        variant='text'
                        sx={{
                          ...(reactionMap[comment.id] == "good" && { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }) // 그림자 효과 추가
                        }}
                        onClick={(e) => onClickCommentGood(e, comment.id)}
                      >
                        {comment.good_count || 0}
                      </Button>
                      <Button
                        startIcon={reactionMap[comment.id] === 'bad' ? <ThumbDown /> : <ThumbDownOutlined />}
                        color="error"
                        variant='text'
                        sx={{
                          ...(reactionMap[comment.id] == "bad" && { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }) // 그림자 효과 추가
                        }}
                        onClick={(e) => onClickCommentBad(e, comment.id)}
                      >
                        {comment.bad_count || 0}
                      </Button>
                    </Box>
                  </Box>
                </Box>
                {index < comments.length && <Divider />}
              </React.Fragment>
            ))
              : <CircularProgress />
          }
        </List>

        <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          {/* 댓글 입력 필드 */}
          <TextField
            label="댓글 입력"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            sx={{ mb: 2, flexGrow: 1, mr: 2 }}
          />

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (!user?.nick_name) {
                // user.nick_name이 없는 경우 로그인 여부 확인
                const confirmLogin = window.confirm("로그인이 필요합니다. 로그인 하시겠습니까?");
                if (confirmLogin) {
                  // 로그인 처리 로직 (예: 로그인 페이지로 이동)
                  handleLoginRedirect();
                }
              } else {
                // 댓글 저장 로직 호출
                onClickCommentSave();
              }
            }}
            sx={{ width: '100px', height: '125px', whiteSpace: 'nowrap' }}
          >
            {commentProgress ? <CircularProgress color="inherit" /> : <Typography variant='button'>댓글저장</Typography>}
          </Button>
        </Box>



      </Box>
      
      <Modal open={showModal} onClose={closeModal}>
          <Box>
              <Join setShowModal={setShowModal} />
          </Box>
      </Modal>
    </>
  );
};

export default Post;


const dummyPosts = {
  1: {
    id: 'sXZwuusDeyiMPN6Kr2UN',
    title: "게시판 제목1",
    created_by: "작성자1",
    content: "게시판 내용1입니다."
  },
  2: {
    id: 'MtFGkcVhEypS6Jjfq66c',
    title: "게시판 제목2",
    created_by: "작성자2",
    content: "게시판 내용2입니다."
  },
};
