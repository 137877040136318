import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Card,
  CardContent,
  CardActions, // eslint-disable-line no-unused-vars
  List,
  ListItem,
  ListItemText,
  Select, MenuItem,
} from '@mui/material';
import TimestampFormatter from "../common/TimestampFormatter";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import ImageResize from 'quill-image-resize';
import CustomAxios from "../common/CustomAxios";
import { DeleteOutline, GarageOutlined, SendOutlined } from "@mui/icons-material";
import { useAtom } from "jotai";
import { isLoadingAtom, userAtom, readPostAtom, dummyCategoryList } from "../../store";
import { checkLogin } from "../login/checkLogin";

// 모듈을 한 번만 등록
if (!Quill.imports['modules/imageResize']) {
  Quill.register('modules/imageResize', ImageResize);
}
// 폰트 옵션 추가
const Font = Quill.import('formats/font');
Font.whitelist = ['my-font', 'sans-serif', 'serif']; // 필요한 폰트 추가
Quill.register(Font, true);

const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    [{ 'font': Font.whitelist }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],
    ['link', 'image', 'video'],
  ],
  clipboard: {
    matchVisual: false
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }
};

const PostModify = () => {
  const navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const { docId } = useParams();
  const quillRef = useRef(null);
  const [category, setCategory] = useState(1);
  const [postUid, setPostUid] = useState();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  // const [response, setResponse] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (user != null) {
      onClickSearch();
      
      //TODO || user.uid != postUid  어떻게 처리할 건지.
      if ( !!user.nick_name == false ) {
        alert("잘못된 접근입니다.");
        navigate("/");
      }
    }
  }, [user]);

  function onClickSearch() {
    const searchParams = new URLSearchParams();
    searchParams.append("docId", docId);

    CustomAxios(`/getPost?${searchParams.toString()}`)
      .then((result) => {
        // console.log(result);

        if (result.status == 200) {
          const res = result.data;
          setTitle(res.result.title);
          setContent(res.result.content);
          setCategory(res.result.category);
          setPostUid(res.result.uid);
        }
      }).catch((err) => {
        console.error(err);
        // throw new Error(`HTTP error! status: ${response.status}`);
        setIsLoading(false);
      });
  }

  function handleChangeCategory(event) {
    setCategory(event.target.value);
  }

  
  const onClickSave = useCallback(async () => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    setIsLoading(true);

    CustomAxios(`/updatePost`, {  // Replace with your API endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed, e.g., authorization
      },
      data: JSON.stringify({
        docId: docId,
        category: category,
        title: title,
        content: content,
        nick_name: user.nick_name
      }),
    }).then((result) => {
      // console.log(result);

      if (result.status == 200) {
        // const res = result.data;
        alert("수정 되었습니다.");
        onClickHistoryBack();
      }
    }).catch((err) => {
      console.error(err);
      // throw new Error(`HTTP error! status: ${response.status}`);
      setIsLoading(false);
    });
  }, [user, docId, category, title, content]);

  const onClickDelete = useCallback(async () => {
    if (await checkLogin(user, setUser, setShowModal) === false) {
      return;
    }

    setIsLoading(true);

    CustomAxios(`/deletePost`, {  // Replace with your API endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed, e.g., authorization
      },
      data: JSON.stringify({
        docId: docId,
        nick_name: user.nick_name
      }),
    })
      .then((result) => {
        // console.log(result);

        if (result.status == 200) {
          // const res = result.data;
          alert("삭제 되었습니다.");
          onClickHistoryBack();
        }
      }).catch((err) => {
        console.error(err);
        // throw new Error(`HTTP error! status: ${response.status}`);
      });
  }, [user]);

  function onClickHistoryBack() {
    navigate(-1);
  }

  return (
    <>
      <Box my={2}>
        <Typography variant="subtitle2" component="div" style={{ display: 'flex', alignItems: 'center' }}>
          카테고리
          <Select style={{ marginLeft: 10 }} value={category} size="small" onChange={handleChangeCategory}>
            {
              dummyCategoryList.map(item => {
                if (item.key !== 0) {
                  return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>;
                }
              })
            }
          </Select>
        </Typography>
        <TextField
          sx={{ mt: "10px" }}
          variant="outlined"
          fullWidth
          label="타이틀"
          size="small"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Divider sx={{ my: 2 }} />

        {/* Quill Editor를 사용하여 내용 표시 */}
        <ReactQuill
          theme="snow"
          modules={modules}
          ref={quillRef}
          value={content}
          onChange={setContent}
          className="custom-quill-editor"
        />

        <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between' }}>

          <Button variant="outlined" onClick={onClickHistoryBack}><Typography variant='button'>이전</Typography></Button>

          <Box sx={{ my: 0, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" startIcon={<DeleteOutline />} onClick={onClickDelete} sx={{ mr: '10px;' }}><Typography variant='button'>삭제</Typography></Button>
            <Button variant="outlined" startIcon={<SendOutlined />} onClick={onClickSave}><Typography variant='button'>수정</Typography></Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PostModify;