import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { userAtom } from '../../store';
import Login from './Login';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate(); // useNavigate 훅 사용하여 navigate 함수 가져오기

    const goMainPage = () => {
        navigate(`/`);
    };

    return (
        <Box display='flex' justifyContent='space-between' alignItems='center'
        // sx={{
        //     // backgroundColor: '#2222DA',
        //     paddingX: 1,
        //     paddingY: 1
        // }}
        >
            <Typography
                variant="h5"
                sx={{ cursor: 'pointer', color: '' }}
                onClick={goMainPage}>
                Running Mania
            </Typography>
            <Box>
                <Login />
            </Box>
        </Box>
    );
};

const dummyCategoryList = [
    {
        key: 1,
        value: '전체'
    },
    {
        key: 2,
        value: "DFAMS"
    },
    {
        key: 3,
        value: "DFAS"
    },
    {
        key: 4,
        value: "ICTHUB"
    },
    {
        key: 5,
        value: "DooEM"
    },
    {
        key: 6,
        value: "ASSET"
    },
    {
        key: 6,
        value: "SHINHYUP"
    },
];


export default Header;
