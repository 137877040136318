import React from 'react';
import { Box, Container, Grid2 } from '@mui/material';
import Header from '../components/main/Header';
import PopularPosts from '../components/main/PopularPosts';
import RecentPosts from '../components/main/RecentPosts';
import Footer from '../components/main/Footer';
import { useAtom } from 'jotai';
import { userAtom } from '../store';
import Menus from '../components/main/Menus';
import Post from '../components/posts/Post';

const PostViewPage = () => {
    const [user] = useAtom(userAtom);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh', // 전체 화면 높이
            }}
        >
            {/* 헤더 영역 */}
            <Container maxWidth="md"
                sx={{
                    padding: '10px 0', // 상하 여백
                }}>
                <Header />
            </Container>

            {/* 메뉴 영역 - 화면 전체 너비 */}
            <Box
                sx={{
                    width: '100%', // 전체 화면 너비
                    backgroundColor: '#f8f8f8', // 배경색 (선택)
                    padding: '10px 0', // 상하 여백
                }}
            >
                <Menus />
            </Box>

            {/* 바디 영역 */}
            <Container
                maxWidth="md"
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                }}
            >
                <Post />
            </Container>

            {/* 푸터 영역 - 화면 전체 너비 */}
            <Box
                sx={{
                    width: '100%', // 전체 화면 너비
                    // backgroundColor: '#f5f5f5', // 배경색 (선택)
                    textAlign: 'center',
                    // padding: '16px 0', // 상하 여백
                }}
            >
                <Footer />
            </Box>
        </Box>
    );
};


export default PostViewPage;
