// Import the functions you need from the SDKs you need
import { getApps, getApp, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithRedirect, OAuthProvider, signInWithCredential } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCnIqCYv9OuBj0XiM7LZC32oEH9F5zzyC8",
  authDomain: "running-f0e25.firebaseapp.com",
  projectId: "running-f0e25",
  storageBucket: "running-f0e25.appspot.com",
  messagingSenderId: "507100247720",
  appId: "1:507100247720:web:53347e0041016ee6fb349d",
  measurementId: "G-X0CCFQFH8G"
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();

// Naver Provider
const naverProvider = new OAuthProvider('oidc.naver_oidc');
naverProvider.setCustomParameters({
  client_id: "mQY7qtmogt3oEJA1UR64",
  client_secret: "5YTfcxVg5k",
  redirect_uri: "http://localhost:3000/auth/handler"
  // redirect_uri: "https://running-f0e25.firebaseapp.com/__/auth/handler"
});


export const handleNaverSignIn = async () => {
  // setError(null);
  try {
    await signInWithRedirect(auth, naverProvider);
  } catch (error) {
    console.error('Naver Sign In Error:', error);
    // setError('Naver Authentication failed.');
  }
};

export const handleFirebaseLogin = async (token) => {
  try {
    // Firebase 인증 제공자 생성
    const provider = new OAuthProvider('oidc.naver_oidc');

    // 사용자 인증
    const credential = provider.credential({
      idToken: token
    });
    const result = await signInWithCredential(auth, credential);

    // 인증 성공 시 사용자 정보 출력
    const user = result.user;
    console.log('User signed in:', user);

    // 추가적인 로직 처리
    // ...
  } catch (error) {
    // 인증 실패 시 오류 처리
    console.error('Error signing in with Naver OIDC:', error);
  }
};